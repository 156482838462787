import {Box, Button, styled, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import React from "react";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 320,
    flexDirection: 'column',
    justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
    width: '100%',
    marginBottom: '15px',
}));

const NotFoundRoot = styled(FlexBox)(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh !important',
}));

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <NotFoundRoot>
            <JustifyBox>
                <IMG src="/assets/images/404.png" alt=""/>
                {/*<Typography component="h5"*/}
                {/*            variant="h5"*/}
                {/*            align="center"*/}
                {/*            color="text.primary"*/}
                {/*            gutterBottom*/}
                {/*            sx={{mb: 2}}>*/}
                {/*    No se encontró la página.*/}
                {/*</Typography>*/}
                <Button
                    color="primary"
                    variant="contained"
                    sx={{textTransform: 'capitalize'}}
                    onClick={() => navigate(-1)}
                >
                    Regresar
                </Button>
            </JustifyBox>
        </NotFoundRoot>
    );
};

export default NotFound;
