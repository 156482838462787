export const topBarHeight = 64
export const sideNavWidth = 290
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

// export const API_URL = 'http://localhost:5000/api/v1/';
// export const API_URL = 'http://192.168.1.13:5000/api/v1/';
// export const API_URL = 'http://192.168.14.169:5000/api/v1/';
export const API_URL = 'https://back-comprobantes.bellatrix.cloud/api/v1/';

export const AG_GRID_LOCALE_ES = {
    // Number Filter & Text Filter
    filterOoo: 'Filtrar...',
    equals: 'Igual',
    notEqual: 'No es igual',
    blank: 'En blanco',
    notBlank: 'No estén en blanco',
    empty: 'Elija uno',
    // Number Filter
    lessThan: 'Menor que',
    greaterThan: 'Mayor que',
    lessThanOrEqual: 'Menor o igual que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'Dentro del rango',
    inRangeStart: 'desde',
    inRangeEnd: 'hasta',
    // Text Filter
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Comience con',
    endsWith: 'Termine con',
    // Filter Conditions
    andCondition: 'Y',
    orCondition: 'O',
    //General
    noRowsToShow: 'Sin resultados...',
    loadingOoo: 'Cargando, por favor espere...',
    //Bar and status
    to: 'a',
    of: 'de',
    page: 'Página',
    nextPage: 'Página Siguiente',
    lastPage: 'Última Página',
    firstPage: 'Primera Página',
    previousPage: 'Página Anterior',
}

export const RECEIPT_TYPE_INVOICE_ID = 1
export const RECEIPT_TYPE_RETENTION_ID = 6
export const RECEIPT_TYPE_REFERRAL_GUIDE_ID = 5
export const EMISSION_TYPE = 1
export const CURRENCY = 'DOLAR'
export const TAX_VAT_CODE = '2'
export const TAX_RATE_VAT_0_CODE = '0'
