import {API_URL} from 'app/utils/constant';
import axios from "axios.js";

export const doLogin = (username, pass) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + 'auth/login', {
            username, pass
        }).then(result => {
            resolve(result)
        }).catch(error => {
            reject(error)
        })
    });
}

export const validToken = (accessToken) => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + 'auth/validToken', {
            headers: {
                Authorization: accessToken,
            }
        }).then(result => {
            resolve(result)
        }).catch(error => {
            reject(error)
        })
    });
}

export const changePassword = (data) => {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("accessToken");
        axios.post(API_URL + 'auth/changePassword', data, {
            headers: {
                Authorization: accessToken,
            }
        }).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    });
}

export const getUserAccountTasks = (accountId, token) => {
    return new Promise((resolve, reject) => {
        const accessToken = token || localStorage.getItem("accessToken");
        axios.get(API_URL + `auth/getUserAccountTasks?account_id=${accountId}`, {
            headers: {
                Authorization: accessToken,
            }
        }).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    });
}