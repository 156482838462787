import useAuth from 'app/hooks/useAuth';
import {Navigate, useLocation} from 'react-router-dom';

const userHasPermission = (pathname, user) => {
    if (!user) {
        return false;
    }
    const routesAllowed = user.tasks.filter(task => task.type === 'R' && task.status === 1)
    const matched = routesAllowed.find((r) => r.value.path === pathname);
    return !!matched
};

const AuthGuard = ({children}) => {
    let {isAuthenticated, user, currentAccount} = useAuth();
    const {pathname} = useLocation();
    const hasPermission = currentAccount ? userHasPermission(pathname, user) : false;

    return (
        <>
            {isAuthenticated ? (
                    user && user.accounts.length > 0 ?
                        (hasPermission ?
                            (children) :
                            (currentAccount ?
                                (<Navigate replace to="/session/404" state={{from: pathname}}/>) :
                                (<Navigate replace to="/session/accountSelect" state={{from: pathname}}/>))) :
                        (<Navigate replace to="/session/signup" state={{from: pathname}}/>)) :
                (<Navigate replace to="/session/signin" state={{from: pathname}}/>)}
        </>
    );
};

export default AuthGuard;
