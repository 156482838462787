export const navigations = [
    // {name: 'Inicio', path: '/dashboard/default', icon: 'dashboard'},
    {label: 'COMPROBANTES ELECTRÓNICOS', type: 'label'},
    {
        name: 'Facturas',
        icon: 'article',
        path: '/receipts/invoices',
    },
    {
        name: 'Retenciones',
        icon: 'article',
        path: '/receipts/withholdings',
    },
    {
        name: 'Inventario',
        icon: 'inventory',
        children: [
            {name: 'Productos/Servicios', iconText: 'P', path: '/inventory/products'},
        ],
    },
    {
        name: 'Configuración',
        icon: 'settings',
        children: [
            {name: 'Cambiar Contraseña', iconText: 'CC', path: '/settings/changePassword'},
            {name: 'Mi Cuenta', iconText: 'CC', path: '/settings/account'},
        ],
    },
    // {label: 'Components', type: 'label'},
    // {
    //     name: 'Components',
    //     icon: 'favorite',
    //     badge: {value: '30+', color: 'secondary'},
    //     children: [
    //         {name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A'},
    //         {name: 'Buttons', path: '/material/buttons', iconText: 'B'},
    //         {name: 'Checkbox', path: '/material/checkbox', iconText: 'C'},
    //         {name: 'Dialog', path: '/material/dialog', iconText: 'D'},
    //         {name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E'},
    //         {name: 'Form', path: '/material/form', iconText: 'F'},
    //         {name: 'Icons', path: '/material/icons', iconText: 'I'},
    //         {name: 'Menu', path: '/material/menu', iconText: 'M'},
    //         {name: 'Progress', path: '/material/progress', iconText: 'P'},
    //         {name: 'Radio', path: '/material/radio', iconText: 'R'},
    //         {name: 'Switch', path: '/material/switch', iconText: 'S'},
    //         {name: 'Slider', path: '/material/slider', iconText: 'S'},
    //         {name: 'Snackbar', path: '/material/snackbar', iconText: 'S'},
    //         {name: 'Table', path: '/material/table', iconText: 'T'},
    //     ],
    // },
    // {
    //     name: 'Charts',
    //     icon: 'trending_up',
    //     children: [{name: 'Echarts', path: '/charts/echarts', iconText: 'E'}],
    // },
    // {
    //     name: 'Documentation',
    //     icon: 'launch',
    //     type: 'extLink',
    //     path: 'http://demos.ui-lib.com/matx-react-doc/',
    // },
];
