import Loadable from 'app/components/Loadable';
import {lazy} from 'react';

const ChangePassword = Loadable(lazy(() => import('./ChangePassword')));
const Account = Loadable(lazy(() => import('./Account')));


const baseRoute = 'settings'
const routes = [
    {
        path: baseRoute + '/changePassword',
        element: <ChangePassword/>
    },
    {
        path: baseRoute + '/account',
        element: <Account/>
    }
]

export default routes
