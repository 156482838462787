import Loadable from 'app/components/Loadable';
import {lazy} from 'react';

const Products = Loadable(lazy(() => import('./Products')));
const Drivers = Loadable(lazy(() => import('./Drivers')));

const baseRoute = 'inventory'
const routes = [
    {
        path: baseRoute + '/products',
        element: <Products/>
    },
    {
        path: baseRoute + '/drivers',
        element: <Drivers/>
    }
]

export default routes
