import Loadable from 'app/components/Loadable';
import {lazy} from 'react';

const Plans = Loadable(lazy(() => import('./Plans')));


const baseRoute = 'subscriptions'
const routes = [
    {
        path: baseRoute + '/plans',
        element: <Plans/>
    }
]

export default routes