import Loadable from 'app/components/Loadable';
import {lazy} from 'react';

const Invoices = Loadable(lazy(() => import('./Invoices')));
const CreateInvoice = Loadable(lazy(() => import('./CreateInvoice')));
const Withholdings = Loadable(lazy(() => import('./Withholdings')));
const CreateWithholding = Loadable(lazy(() => import('./CreateWithholding')));
const ReferralGuides = Loadable(lazy(() => import('./ReferralGuides')));
const CreateReferralGuide = Loadable(lazy(() => import('./CreateReferralGuide')));


const baseRoute = 'receipts'
const routes = [
    {
        path: baseRoute + '/invoices',
        element: <Invoices/>
    },
    {
        path: baseRoute + '/createInvoice',
        element: <CreateInvoice/>
    },
    {
        path: baseRoute + '/withholdings',
        element: <Withholdings/>
    },
    {
        path: baseRoute + '/createWithholding',
        element: <CreateWithholding/>
    },
    {
        path: baseRoute + '/referralGuides',
        element: <ReferralGuides/>
    },
    {
        path: baseRoute + '/createReferralGuide',
        element: <CreateReferralGuide/>
    },
]

export default routes
