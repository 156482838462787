import AuthGuard from 'app/auth/AuthGuard';
import receiptRoutes from 'app/views/receipts'
import settingRoutes from 'app/views/settings'
import inventoryRoutes from 'app/views/inventory'
import subscriptionRoutes from 'app/views/subscriptions'
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import {Navigate} from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
    {
        element: (
            <AuthGuard>
                <MatxLayout/>
            </AuthGuard>
        ),
        // children: [...dashboardRoutes, ...chartsRoute, ...materialRoutes, ...receiptRoutes],
        children: [...receiptRoutes, ...settingRoutes, ...inventoryRoutes, ...subscriptionRoutes],
    },
    ...sessionRoutes,
    {path: '/', element: <Navigate to="receipts/invoices"/>},
    {path: '*', element: <NotFound/>},
];

export default routes;
