import React, {createContext, useEffect, useReducer} from 'react'
import axios from 'axios.js'
import {MatxLoading} from 'app/components'
import {doLogin, getUserAccountTasks, validToken} from "../actions/Auth";
import {registerClient} from "../actions/Clients";

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    account: null,
    currentAccount: null
}

const setSession = (data) => {
    if (data && data.token) {
        localStorage.setItem('accessToken', data.token)
        localStorage.setItem('user', JSON.stringify(data.user))
        axios.defaults.headers.common.Authorization = `${data.token}`
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common.Authorization
    }
}

const setSessionUser = (user) => {
    if (user) {
        localStorage.setItem('user', JSON.stringify(user))
    } else {
        localStorage.removeItem('user')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const {isAuthenticated, user, account, currentAccount} = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                account,
                currentAccount
            }
        }
        case 'LOGIN': {
            const {user, currentAccount} = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
                account: null,
                currentAccount
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                account: null
            }
        }
        case 'REGISTER': {
            const {user, account} = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
                account
            }
        }
        default: {
            return {...state}
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {
    },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const getUserTasksByAccount = async (accountId, token) => {
        try {
            const result = await getUserAccountTasks(accountId, token)
            if (result && result.success) {
                return result.data
            } else {
                return null
            }
        } catch (e) {
            return null
        }
    }

    const login = async (email, password) => {
        const result = await doLogin(email, password);
        const {success, data} = result.data

        if (success) {
            const defaultAccount = data.user.accounts.filter(account => account.usersRoles.length > 0 && account.usersRoles[0].default === 1)
            const currentAccount = defaultAccount.length > 0 ? defaultAccount[0] : null
            if (currentAccount) {
                data.user.tasks = await getUserTasksByAccount(currentAccount.id, data.token)
                data.user.currentAccount = currentAccount
            }
            setSession(data)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: data.user,
                    currentAccount
                },
            })
        } else {
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: data,
                    account: null,
                    currentAccount: null
                },
            })
        }
    }

    const register = async (dataInput) => {
        const result = await registerClient(dataInput);
        const {success, data} = result.data

        let userAux = state.user
        if (success) {
            userAux.accounts = data
            const defaultAccount = userAux.accounts.filter(account => account.usersRoles.length > 0 && account.usersRoles[0].default === 1)
            const currentAccount = defaultAccount.length > 0 ? defaultAccount[0] : null
            if (currentAccount) {
                userAux.tasks = await getUserTasksByAccount(currentAccount.id, null)
                userAux.currentAccount = currentAccount
            }
            setSessionUser(userAux)
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: true,
                    user: userAux,
                    currentAccount
                },
            })
        } else {
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: userAux,
                    account: data,
                    currentAccount: null
                },
            })
        }
    }

    const logout = () => {
        setSession(null)
        dispatch({type: 'LOGOUT'})
    }

    const isValidToken = async (accessToken) => {
        const result = await validToken(accessToken)
        const {success} = result.data
        return success
    }

    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const userData = window.localStorage.getItem('user')

                if (accessToken && userData && await isValidToken(accessToken)) {
                    const user = JSON.parse(userData)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                            currentAccount: user.currentAccount
                        },
                    })
                } else {
                    setSession(null)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            currentAccount: null
                        },
                    })
                }
            } catch (err) {
                setSession(null)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        currentAccount: null
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading/>
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
