import {API_URL} from 'app/utils/constant';
import axios from "axios";

export const registerClient = (data) => {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("accessToken");
        axios.post(API_URL + 'client/register', data, {
            headers: {
                Authorization: accessToken,
            }
        }).then(result => {
            resolve(result)
        }).catch(error => {
            reject(error)
        })
    });
}

export const getEmissionPoints = (receiptTypeId, accountId) => {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("accessToken")
        axios.get(API_URL + `client/emissionPoints?clientId=${accountId}&receiptTypeId=${receiptTypeId}`, {
            headers: {
                Authorization: accessToken,
                Account: accountId
            }
        }).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    });
}

export const getPersonByIdentification = (identification) => {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("accessToken")
        axios.get(API_URL + 'client/personByIdentification?identification=' + identification, {
            headers: {
                Authorization: accessToken
            }
        }).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    });
}

export const updateSignature = (data, accountId) => {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("accessToken");
        axios.post(API_URL + 'client/updateSignature', data, {
            headers: {
                Authorization: accessToken,
                Account: accountId
            }
        }).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    });
}